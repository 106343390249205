import React from 'react';
import '../../../styles/TokyoAcademy.css';
import { Grid } from '@mui/material';

export default function Adult() {
  return (
    <div className='table_container'>
        <div>
            <div className='table_header'>OCTOBER 2023</div>
            <Grid container className='table_row'>
              <Grid item lg={3}>
                Adult 
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <div className='table_header'>NOVEMBER 2023</div>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <div className='table_header'>DECEMBER 2023</div>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
            <Grid container className='table_row'>
              <Grid item lg={3}>
              Adult
              </Grid>
              <Grid item lg={3}>
              Date
              </Grid>
              <Grid item lg={3}>
              Time
              </Grid>
              <Grid item lg={3}>
              Locations
              </Grid>
            </Grid>
        </div>
    </div>
  )
}
